/**
 *APP CONFIG - FRONTEND
 */
var location = window.location;
var domaine = location.protocol + "//" + location.hostname + "/";
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  domaine =
    location.protocol + "//" + location.hostname + ":" + location.port + "/";
}

const Config = {
  auth: {
    USER: "Recruiter Only",
    PASSWORD: process.env.REACT_APP_AUTH_PASS,
  },
  app: {
    DOMAINE: domaine,
  },
  api: {
    weather: {
      APPID: process.env.REACT_APP_WEATHER_APPID,
      END_POINT: "https://api.openweathermap.org/",
      URI: "data/2.5/weather?",
    },
    "auto-complet": {
      END_POINT: "https://api-adresse.data.gouv.fr/",
      URI: "search/?",
    },
    "map-quest": {
      APPID: process.env.REACT_APP_MAP_QUEST_APPID,
      END_POINT: "https://www.mapquestapi.com/",
      URI_ROUTE: "directions/v2/route?",
      URI_SHAPE: "directions/v2/routeshape?",
    },
    "map-box": {
      APPID: process.env.REACT_APP_MAP_BOX_APPID,
    },
  },
};

export default Config;
