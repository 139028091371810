import React from "react";
import { NavLink } from "react-router-dom";
import "./planet3DSight.css"
import Planet from "./planet/Planet";

export default function Planet3DSight( {i18n , lang}){
    return (
      <>
        <div className="container my-5">
          <div className="row">
            <div className="col">
              <h3 className="text-primary">{i18n?.title[lang]}</h3>
              <p>{i18n?.description[lang]}</p>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <div className="row text-center">
            <div className="col planet-sight">
              <div className="planet">
                <img src="img/threejs/milkyway.jpg" alt="space"/>
              </div>
              <Planet />
            </div>
          </div>
          <div className="row">
            <div className="col my-5 text-center">
              <NavLink to="/demo">
                <button className="btn btn-success btn-lg">
                  {i18n?.title[lang]}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }


