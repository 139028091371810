import React, { Component } from "react";
import "./about.css";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import { ContextConsumer } from '../../../../context/context';
import YoutubePlayer from "../../../partials/players/video/YoutubePlayer";

class About extends Component {

  componentDidMount = () =>{
    AOS.init({duration:2000})
  }
  render() {
    return (
      <ContextConsumer> 
      { (props) => {

        const i18about = props.i18n?.portfolio?.about;

        return (
          <div data-aos="fade-in" className="about container" id="about">
            <div className="row my-5">
              <div className="col mb-5">
              
                <div className="d-flex flex-wrap">
                  <h3 className="my-5 introduction">
                  { i18about?.self_introduction?.[props.lang] }
                  </h3>
                </div>

                <h3 className="my-5 text-primary"> { i18about?.profil?.[props.lang] }</h3>
                <div className="profil">
                  <p>
                    <img src="img/photo-cyril.PNG" alt="profil" />
                  </p>
                  <p>
                  { i18about?.short_resume_part1?.[props.lang] } {" "}
                    <a
                      target="_blanc"
                      href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000036805869&categorieLien=id"
                    >
                      <span
                        className="text-primary"
                        title="Voir le détail du titre sur Légifrance !!"
                      >
                    { i18about?.short_resume_part2?.[props.lang] }
                      </span>
                    </a>{" "}
                    { i18about?.short_resume_part3?.[props.lang] }
                  </p>
                  <p>
                    { i18about?.short_resume_part4?.[props.lang] }
                  </p>
                  <p>
                    { i18about?.short_resume_part5?.[props.lang] }
                  </p>
                
                  <p>
                    { i18about?.follow_part1?.[props.lang] }{" "}
                    <NavLink to="/downloads"> { i18about?.download?.[props.lang] }</NavLink>, { i18about?.follow_part2?.[props.lang] }
                  </p>
                </div>
              </div>
            </div>
            {props.lang === 'en' && <YoutubePlayer url={"https://www.youtube.com/embed/VzGfOQPr9xg?si=oG1gC9J5vSusmkp8"} className='player'/> }
            {props.lang === 'fr' &&  <YoutubePlayer url={'https://www.youtube.com/embed/t3zuJGoUqgY?si=op5EOWLSjI9namsU'} className='player'/>}
          </div>);
        }}
    </ContextConsumer>
    )
  }
}

export default About;