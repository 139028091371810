import React, { Component } from "react";
import "./header.css";
import PlayerAudio from "../../../partials/players/audio/PlayerAudio";
import Typewriter from "typewriter-effect";
import AOS from "aos";
import { ContextConsumer } from '../../../../context/context';

class Header extends Component {
  constructor(props) {
    super(props);
    this.maxBackgrounds = 11;
    this.state = { 
      backgroundIndex: 9, 
      isVisible:true
    };
  }

  componentDidMount = () => {
    AOS.init({duration: 3000})
    const options = {
      root: null,
      rootMargin: window.innerHeight + "px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(this.intersectCallback, options);
    const header = document.querySelector(".header");
    observer.observe(header);
  };

  intersectCallback = () => {
    const copyState = { ...this.state };
    if(!this.state.isVisible){
      copyState.backgroundIndex =
      this.state.backgroundIndex !== this.maxBackgrounds
      ? copyState.backgroundIndex+1
      : 0;
    }
    copyState.isVisible = this.state.isVisible ? false: true
    this.setState(copyState);
  };

  handleClick = () => {
    
    const about = document.getElementById("about");
    about.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };


  setPosition = () => {
    this.props.handleMouseEnter(0)
  }

  render() {

    return (
      <ContextConsumer> 
        { ({i18n, lang}) => {

          const i18nHeader = i18n?.portfolio?.header

          return (
            <header
              data-aos="fade-in"
              className="header"
              id="header"
              onMouseEnter={this.setPosition}
              style={{
                backgroundImage: `url("/img/banners/background-${this.state.backgroundIndex}.jpg")`,
              }}
            >
              <div className="text-center h6">Sorry my website is not fully translated. I work on it in my spare time ! </div>
              <div className="row my-5">
                <div className="col">
                  <h2>Cyril VASSALLO</h2>
                  <h3 className="text-center text-primary">
                  { i18nHeader?.job?.[lang] }<br />
                    <Typewriter
                      options={{
                        strings: [
                          "React.js",
                          "Next.js",
                          "Firebase",
                          "React Native",
                          "CSS-SCSS",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </h3>
                </div>
              </div>
              <img src="./svg/logo.svg" className="logo" alt="logo" />

              <p className="mt-3">{i18nHeader?.welcome?.[lang]  }</p>
              <a onClick={this.handleClick} className="link" href="#me">
                { i18nHeader?.presentation?.[lang] }
              </a>
              <div style={{display:"flex", margin: "auto"}}>
                <div className="m-4">
                  <img src="/img/english-flag.png" alt="" style={{width: "45px", height:'45px'}} onClick={() => this.props.handleLangChange('en')}/>
                </div>
                <div className="m-4">
                  <img src="/img/french-flag.png" alt="" style={{width: "45px", height:'45px'}} onClick={() => this.props.handleLangChange('fr')}/>
                </div>
              </div>
              <PlayerAudio audio="Plongee-Nocturne.mp3" i18n={i18nHeader} lang={lang} />
          </header>)
        } }
      </ContextConsumer>
    );
  }

}

export default Header;
