import React from 'react';
import Spacer from "../../../partials/spacer/Spacer";
import Presentation from "../../../partials/slider/Slider";
import YoutubePlayer from '../../../partials/players/video/YoutubePlayer';


function Project({project, lang, index}) {

    return (
      <>
        <div className="row my-5">
          <div className="col">
            <h4>{project?.title[lang]}</h4>
            <p className="text-primary">{project?.date[lang]}</p>
            <p>
              {project?.description[lang]}
            </p>
          </div>
        </div>
        <div className="row my-5" id={`details${index}`} style={{ display: "none" }}>
          <div className="col-md-6">
            <h5 className="text-success">{project?.environment_title[lang]}</h5>
            <p>
              {project?.environment[lang]}
            </p>
            <h5 className="text-success">{project?.scope_title[lang]}</h5>
            <p>
              {project?.scope[lang]}
            </p>
            <ul>
              {
                project?.scope_list[lang]?.map((item, index)=> {
                  return (
                    <li key={index}>{item}</li>
                  )
              })}
            </ul>
            <h5 className="text-success">
              {project?.duties_title[lang]}
            </h5>
            <ul>
            {
                project?.duties_list[lang]?.map((item, index)=> {
                  return (
                    <li key={index} >{item}</li>
                  )
              })}
            </ul>
          </div>
          <div className="col-md-6">
            <h5 className="text-success">{project?.medias?.title[lang]}</h5>
            {project?.medias?.type === "images" && <Presentation images={project?.medias?.contents} lang={lang}/>}
            {project?.medias?.type === "video" && <YoutubePlayer title={project?.medias?.contents[0]?.title[lang]} url={project?.medias?.contents[0]?.path}/>}
          </div>
        </div>
        <Spacer spacerNumber={index} />
      </>
    );
    
}

export default Project;