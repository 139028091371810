// React
import React, { Component } from "react";

// Context i18n and SubNav
import { ContextProvider } from '../../context/context'

// Custom Components
import Skills from "../../components/portfolio/skills/Skills";
import Career from "../../components/portfolio/career/Career";
import Contact from "../../components/portfolio/contact/Contact";
import About from "../../components/portfolio/introduction/about/About";
import Demonstrations from "../../components/portfolio/demonstrations/Demonstrations";
import Productions from "../../components/portfolio/productions/Productions";
import Header from "../../components/portfolio/introduction/header/Header";
import AbsolutNav from "./../../components/partials/absoluteNav/AbsoluteNav";
import Footer from "./../../components/partials/footer/Footer";
import Pricing from "../../components/portfolio/pricing/Pricing";
import FetchData from "../../services/FetchData";

import "./portfolio.css";

class Portfolio extends Component {
  constructor() {
    super()
    this.state = {
      subNavPosition: 0,
      lang: 'en',
      i18n: null
    };
  }

  componentDidMount = async () => {
    const fetchData = new FetchData();
    try {
      await fetchData.getData(this.fetchSuccess, "i18n");
    } catch (error) {
      console.log(error);
    }
  };

  fetchSuccess = (data) => {
    const copyState = { ...this.state };
    copyState.i18n = data.i18n;
    this.setState(copyState);
  };

  handleMouseEnter = position => {
    const copyState = { ...this.state };
    copyState.subNavPosition = position;
    this.setState(copyState);
  };

  handleLangChange = (lang) => {
    const copyState = { ...this.state };
    copyState.lang = lang
    this.setState(copyState);
  };

  render() {
    return (
      <ContextProvider value={this.state}>
        <main className="portfolio">
          <AbsolutNav position={this.state.subNavPosition} />
          <Header handleMouseEnter={this.handleMouseEnter} handleLangChange={this.handleLangChange} />
          <About handleMouseEnter={this.handleMouseEnter}  />
          <Career handleMouseEnter={this.handleMouseEnter} />
          <Skills handleMouseEnter={this.handleMouseEnter} />
          <Pricing handleMouseEnter={this.handleMouseEnter} />
          <Productions handleMouseEnter={this.handleMouseEnter} />
          <Demonstrations handleMouseEnter={this.handleMouseEnter} />
          <Contact handleMouseEnter={this.handleMouseEnter} />
          <Footer />
        </main>
      </ContextProvider>
    );
  }
}

export default Portfolio;
