import React, { Component } from "react";
import Banner from "../../partials/banner/Banner";
import Technologies from './technologies/Technologies'
import { ContextConsumer } from '../../../context/context';

class Skills extends Component {
  constructor(props)
  {
    super(props)
    this.state = {
    }
  }
  render() {

    return (
      <ContextConsumer>
       {
        ({i18n, lang}) => {
          const  i18nSkills = i18n?.portfolio?.skills;
          return (
            <div className="skills container-fluid" id="skills">
              <div className="row">
                <Banner
                  title={i18nSkills?.section_title?.[lang]}
                  background="background-2.jpg"
                  position = {2}
                  handleMouseEnter={this.props.handleMouseEnter}
                />
                <div className="col">
                  <Technologies />
              </div>
            </div>
          </div>
          )
        }
      } 
    </ContextConsumer>
    )
  }
}

export default Skills;
