import React, { useContext } from "react";
import "./priceCards.css";
import Context from "../../../../context/context"

export default function PriceCards(){

    const { i18n, lang } = useContext(Context);
    const i18nPriceCards = i18n?.portfolio?.pricing?.priceCards;

    const cards =  i18nPriceCards?.map( (priceCard, index) =>   {
        return (
            <div key={index} className={`priceCard ${index === 0 ? 'gold':  '' }`}>
                <h4 className={`priceCard-title ${index === 0 ? 'gold':  ''}`}>{priceCard?.title[lang]}</h4>
                <p className="priceCard-period">{priceCard?.period[lang]}</p>
                <p className={`priceCard-task ${index === 0 ? 'gold':  ''}`}>{priceCard?.task[lang]}</p>
                <p className="priceCard-tjm">{priceCard?.tjm[lang]}</p>            
            </div>
        )
    });

    return (
        <div className="priceCards">
          { cards }
        </div>
    );
} 
