import React from 'react';
import './pet.css';


export default function Pet() {

    const handleClickPet = (event) => {
      console.log('here')
      document.querySelector('#welcome').play();
    }

    return(
      <div className="pet" onClick={handleClickPet}>
        <div className="circle1" >
          <div className="circle3">
          </div>
        </div>
        <div className="circle2">
        </div>
        <audio id="welcome">
            <source src="media/music/welcome.mp3" />
        </audio>
      </div>
    )

}