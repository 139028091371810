import React, { Component } from 'react';
import "./career.css";
import Banner from '../../partials/banner/Banner';
import Timeline from './timeline/Timeline';
import { ContextConsumer } from '../../../context/context';


class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }



  render() {

    return (
      <ContextConsumer>
       {
        ({i18n, lang}) => {
          const  i18nCarrer = i18n?.portfolio?.carrer;
          return (
            <div className="career" id="career" onMouseEnter={this.setPosition}>
            <Banner
              title={i18nCarrer?.section_title?.[lang] }
              background="background-3.jpg"
              position={1}
              handleMouseEnter={this.props.handleMouseEnter}
            />
            <Timeline/>
          </div>
          )
        }
       } 
      </ContextConsumer>
    )
    
  }
}

export default Career;