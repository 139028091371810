import React, { Component } from "react";
import Banner from "../../partials/banner/Banner";
import PriceCards from "./cards/PriceCards";
import { ContextConsumer } from "../../../context/context";
import "./pricing.css";
 import ServiceCards from "./cards/serviceCards";

class Pricing extends Component {

  render() {
    return(
    <ContextConsumer>
      { ({i18n, lang}) => {
          const i18nPricing = i18n?.portfolio?.pricing;
          
          return (
            <div className="pricing" id="pricing">
              <Banner
                title={i18nPricing?.section_title[lang]}
                background="background-11.jpg"
                position={3}
                handleMouseEnter={this.props.handleMouseEnter}
              />
              <div className="container-pricing">
                <PriceCards/>
              <div className="text-center">{i18nPricing?.policy[lang]}</div>
              </div>

              {/* //TODO uncomment to activate services */}
              <div className="container-services"> 
                 <p>
                  {i18nPricing?.serviceDescription[lang]}
                </p>
                <ServiceCards/>
              </div>
      
            </div>
          );
        }
      }
    </ContextConsumer>
    )
  }
}

export default Pricing;
