import React, { useContext } from "react";
import "./serviceCards.css";
import Context from "../../../../context/context"
import YoutubePlayer from "../../../partials/players/video/YoutubePlayer";

export default function ServiceCards(){

    const { i18n, lang } = useContext(Context);
    const i18nServiceCards = i18n?.portfolio?.pricing?.serviceCards;

    const cards =  i18nServiceCards?.map( (serviceCard, index) =>   {
        return (
            <div key={index} className={`serviceCard `}>
                <h4 className={`serviceCard-title`}>{serviceCard?.service[lang]}</h4>
                <p className="serviceCard-description">{serviceCard?.description[lang]}</p>
                <p className="serviceCard-price">{serviceCard?.price[lang]}</p>     
                <p className={`serviceCard-task`}>{serviceCard?.details[lang]?.map((detail, index) => (<React.Fragment key={index}>
                     {">"} {detail}
                </React.Fragment>))}</p>
               {serviceCard?.url !== '' ? <YoutubePlayer url={serviceCard?.url} /> : <img src='/img//hireme.jpeg'alt='hire me'/>}
            </div>
        )
    });

    return (
        <div className="serviceCards">
          { cards }
        </div>
    );
} 
