import React, { Component } from "react";
import Banner from "../../partials/banner/Banner";
import FetchData from "../../../services/FetchData";
import { ContextConsumer } from "../../../context/context";
import Project from "./project/Project";
import "./productions.css";

class Productions extends Component {
  constructor(props){
  super(props);
  this.state = {
      data: null,
      error: null,
    };
  }

  componentDidMount = async () => {
    const fetchData = new FetchData();
    try {
      await fetchData.getData(this.fetchSuccess);
    } catch (error) {
      this.fetchFailed(error);
    }
  };

  fetchSuccess = (data) => {
    const copyState = { ...this.state };
    copyState.data = data;
    this.setState(copyState);
  };

  fetchFailed = (error) => {
    const copyState = { ...this.state };
    copyState.error = error.message;
    this.setState(copyState);
  };

  render() {
    return (
    <ContextConsumer>
      { ({i18n, lang}) => {
        const i18nProductions = i18n?.portfolio?.productions;
        const i18nProjects = i18n?.portfolio?.productions?.projects;

        return (
          <div className="productions" id="productions">
            <Banner
              title={i18nProductions?.section_title[lang]}
              background="background-9.jpg"
              position={4}
              handleMouseEnter={this.props.handleMouseEnter}
            />
            <div className="container project my-5">
              {i18nProjects?.map((project, index) => {
                return (<Project key={index} project={project} lang={lang} index={(index + 1).toString()}/>)
              })}
            </div>
          </div>
        );
      }}
    </ContextConsumer>
    )
    
  }
}

export default Productions;
