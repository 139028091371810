// React
import React from "react";

// Custom component
import Scene from "../../components/demo/threejsScene/ThreejsScene";

export default function Demo() {
    return <Scene />;
}

