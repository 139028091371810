// React
import React from "react";
import { useLocation, Navigate } from "react-router-dom";

// Context
import AuthConsumer from "../../hook/useAuth";

/**
 * Ce wrapper vérifie dans le context si l’utilisateur est connecté sinon, il redirige vers la mire de connexion.
 * @param {Object} props
 * @param {Object} props.children
 * @returns 
 */
export default function RequireAuthGuard({ children }) {
  const { authed } = AuthConsumer();
  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}