import React, { Component } from "react";
import { fadeOutEffect, fadeInEffect } from "../../../services/utils";
import { ContextConsumer } from "../../../context/context";
import "./absoluteNav.css";

class AbsoluteNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      navigation: null
    };
  }

  handleNewPosition = () => {
    const copyState = { ...this.state };
    copyState.position = this.props.position;
    copyState.navigation = this.navigation.map((nav, key) => {
      if (key === copyState.position) {
        return {
          subCircleClass: "subCircleActive",
          navigationClass: "navActive",
          title: nav.title,
          target: nav.target,
        };
      } else {
        return {
          subCircleClass: "subCircle",
          navigationClass: "nav",
          title: nav.title,
          target: nav.target,
        };
      }
    });

    this.setState(copyState);
  };

  handleClickClose = () => {
    const html = document.querySelector("html");
    html.style.overflow = "visible ";
    const subNav = document.querySelector("#subNav");
    const arrow = document.querySelector("#arrow");
    fadeOutEffect(subNav);
    fadeInEffect(arrow);
  };

  handleClickOpen = () => {
    this.handleNewPosition();
    const html = document.querySelector("html");
    html.style.overflow = "hidden";
    const subNav = document.querySelector("#subNav");
    const arrow = document.querySelector("#arrow");
    fadeInEffect(subNav);
    fadeOutEffect(arrow);
  };

  handleClickScrollTo = (event, elementKey) => {
    const copyState = { ...this.state };
    copyState.navigation = this.navigation.map((nav, key) => {
      if (elementKey === key) {
        return {
          subCircleClass: "subCircleActive",
          navigationClass: "navActive",
          title: nav.title,
          target: nav.target,
        };
      } else {
        return {
          subCircleClass: "subCircle",
          navigationClass: "nav",
          title: nav.title,
          target: nav.target,
        };
      }
    });

    this.setState(copyState);
    const targetId = document.querySelector(
      "#" + event.target.getAttribute("target")
    );
    targetId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    this.handleClickClose();
  };
  render() {
    return (
    <ContextConsumer>
      { ({i18n, lang}) => {
        this.navigation = i18n?.navigation?.items;
        const navigationTitle = i18n?.navigation?.title[lang] || '';
        return (
          <>
          <div className="arrow container-fluid" id="arrow" 
            onClick={this.handleClickOpen}>
            <div className="row">
              <div className="col">
                <p className="absoluteNav-toggle-btn">
                  <span>
                    <img src="svg/arrow.svg" alt="" />
                  </span>{" "}
                    {navigationTitle }
                </p>
              </div>
            </div>
          </div>
          <div className="subNav container-fluid" id="subNav">
            <div className="row">
              <div className="col">
                <img
                  className="close"
                  src="svg/close.svg"
                  alt="close"
                  onClick={this.handleClickClose}
                />
              </div>
            </div>
            <div className="row">
              <div className="col box">
                {" "}
                <div className="box1">
                  {this.state.navigation?.map((subCircle, key, navigations) => {
                    return (
                      <div key={key}>
                        <div key={key} className="circle">
                          <div
                            className={subCircle.subCircleClass}
                          >
                            <div className="innerCircle"
                              id={"innerCircle" + key}
                              target={subCircle.target}
                              onClick={(event) => {
                                this.handleClickScrollTo(event, key);
                              }}
                            ></div>
                          </div>
                        </div>
                        {key !== navigations.length - 1 ? (
                          <div className="pipe"></div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="box2">
                  {this.navigation?.map((nav, key, navigations) => {
                    return (
                      <div key={key}>
                        <div
                          className={nav.navigationClass}
                          target={nav.target}
                          id={"nav" + key}
                          onClick={(event) => {
                            this.handleClickScrollTo(event, key);
                          }}
                        >
                          {nav?.label[lang]}
                        </div>
                        {key !== navigations.length - 1 ? (
                          <div className="space"></div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
        );
      }}
    </ContextConsumer>
    )
    
  }

}

export default AbsoluteNav;
