// React
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// Tree.js
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

// Style 
import "./threejsScene.css";

class Scene extends Component {
  constructor({isLogin}) {
    super();
    this.isLogin = isLogin;
    this.audioElement = new Audio(isLogin ? "media/music/Plongee-Nocturne.mp3": "media/music/Gamma.mp3");
    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();
    this.rockGroup = new THREE.Group();
    this.textureLoader = new THREE.TextureLoader();
    this.renderer =  new THREE.WebGLRenderer({
      autoClear: true,
      antialias: true,
      alpha: true,
    });
    this.cameraDistance = 700;
    this.isMobileDevice = null;
    this.texturePath = 'img/threejs/'

    this.sizes = null;
    this.ratio = null;
    this.camera = null;
    this.canvas = null;
    this.controls = null;
    this.card = null;
    this.cardGeometry = null;
    this.cardMaterial = null;
    this.sun = null;
    this.sunGeometry = null;
    this.sunMaterial = null;
    this.planet = null;
    this.planetGeometry = null;
    this.planetMaterial = null;
    this.resizeEvent = null;
    this.rockGeometry = null;
    this.rockMaterial = null;
    this.pointLight = null;
    this.ambientLight = null;
    this.pointLightHelper= null;

    // Galaxie part
    this.geometry = null;
    this.material = null;
    this.galaxie = null;
    this.parameters = {}

    this.availableTextures = [
      'rock',
      'planet',
      'fiction',
      'earth',
      'earth-night'
    ]
  }

  /**
   * Script Start Execution on component
   */
  componentDidMount = () => {
    console.time('Total-Loading')
    if(!this.props.isLogin)this.audioElement.play();
    this.init();
    this.generateSun();
    this.generatePlanet();
    this.generateCard();
    this.generateRocks();
    this.generateLights();
    this.generateGalaxie(this.parameters.galaxiePosition)
    this.animations();
    console.timeEnd('Total-Loading')
  };

  /**
   * Script stop Execution on component
   */
  componentWillUnmount = () => {
    this.audioElement.pause();
    this.clearScene();
  };

  /**
   * Three.js
   * remove geometries, materials and meshes from the scene
   */
  clearScene = () => {


    if(this.rockGroup.children.length > 0) {
      this.planet.remove(this.rockGroup) 
    }

    if(this.sun !== null  ) {
      this.sunMaterial.dispose()
      this.sunGeometry.dispose()
      this.scene.remove(this.sun)
    }

    if(this.planet !== null) {
      this.planetMaterial.dispose()
      this.planetGeometry.dispose()
      this.scene.remove(this.planet)
    }
    
    if(this.card !== null) {
      this.cardGeometry.dispose()
      this.cardMaterial.dispose()
      this.scene.remove(this.card)
    }

    if(this.pointLight !== null){
      this.scene.remove(this.pointLight)
    }

    if(this.ambientLight !== null){
      this.scene.remove(this.ambientLight)
    }

    if( this.galaxie !== null){
      this.geometry.dispose()
      this.material.dispose()
      this.scene.remove(this.galaxie)
  }

    
    this.renderer.renderLists.dispose();
    this.renderer.clear();
  };

 

  /**
   * Initialize scene, camera, control and canvas
   */
  init = () => {

    //Scene and  parameters 
    const sceneBackgroundTexture = this.textureLoader.setPath(this.texturePath).load(`${this.isLogin ? 'milkyway': 'space'}.jpg`)
    this.scene.background = sceneBackgroundTexture;
    this.textureLoader.setPath(this.texturePath)

    this.parameters.axes = false
    this.parameters.count = 100000
    this.parameters.size = 0.01
    this.parameters.radius = 5
    this.parameters.arms = 3
    this.parameters.spin = 0.8
    this.parameters.randomness = 0.2
    this.parameters.randomnessPower = 2
    this.parameters.insideColor = '#ff6030'
    this.parameters.outsideColor = '#1b3984'
    this.parameters.galaxiePosition = {x:-6000, y:2100,z:-10000}


    // Sizes
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.resizeEvent = window.addEventListener('resize', () => {
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;
      this.ratio = this.sizes.width / this.sizes.height;

      this.camera.aspect = this.ratio;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(this.sizes.width, this.sizes.height)
    })


    this.ratio = this.sizes.width / this.sizes.height;

    // Init camera
    const fos = 45;
    const near = 1;
    const far = 200000;
    this.camera = new THREE.PerspectiveCamera(fos, this.ratio, near, far);
    this.camera.position.z = this.cameraDistance;
    this.camera.lookAt(this.scene.position);

   
    // Init renderer and canvas
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    // Canvas
    this.canvas = document.querySelector("#web-gl-scene");
    this.canvas.appendChild(this.renderer.domElement);

    // Init Control
    this.controls = new OrbitControls(this.camera , this.canvas);
    this.controls.enableDamping = true
    if(this.props.isLogin) this.controls.enabled = false;
   

  };

  generateSun = () => {
    console.time('sun')
    console.log('generate Sun')
    const texture = this.textureLoader.load("sun.jpg");
    texture.generateMipmaps = false
    texture.minFilter = THREE.NearestFilter;
    this.sunGeometry = new THREE.SphereBufferGeometry( 300, 30, 30);
    this.sunMaterial = new THREE.MeshStandardMaterial({
      map: texture,
      opacity: 1,
    });
    this.sun = new THREE.Mesh(this.sunGeometry, this.sunMaterial);
    this.sun.name = "Sun";
    this.scene.add(this.sun);
    console.timeEnd('sun')
  };

  generatePlanet = () => {
    console.time('planet')
    console.log('generate Planet')
    const randomTextureName = this.availableTextures[Math.floor(Math.random() * this.availableTextures.length)];
    const texture = this.textureLoader.load(`${randomTextureName}.jpg`);

    this.planetGeometry = new THREE.SphereBufferGeometry(40, 20, 20);
    this.planetMaterial = new THREE.MeshStandardMaterial({
      map: texture,
      opacity: 1,
    });
    this.planet = new THREE.Mesh(this.planetGeometry, this.planetMaterial)
    this.planet.name =  'planet'
    this.planet.position.x = -80;
    this.planet.position.y = 0;
    this.planet.position.z = 500;
    this.sun.add(this.planet);
    console.timeEnd('planet')
  };

 
  generateCard = () => {
    console.time('card')
    console.log('generate Card')
    const length = 16,
      width = 9,
      depth = 0.3
    
    const texture = this.textureLoader.load('card.png');
    // texture.minFilter = THREE.NearestFilter;
    this.cardGeometry = new THREE.BoxGeometry(length, width, depth);

    //A material need texture or a basic material
    this.cardMaterial = new THREE.MeshPhysicalMaterial({
      map: texture,
      opacity: 1,
    });
    this.cardMaterial.clearcoat = 1 

    //A Mesh need a geometry and material object
    this.card = new THREE.Mesh(this.cardGeometry, this.cardMaterial);
    this.card.name = "Card";
    this.card.position.x = 5;
    this.card.position.y = 0;
    this.card.position.z = 680;
    this.scene.add(this.card);
    console.timeEnd('card')
  };


  generateRocks = () => {
    console.time('rocks')
    console.log('generate Rocks')
    const texture = this.textureLoader.load('rock.jpg');
    this.rockMaterial = new THREE.MeshPhysicalMaterial({
      map: texture,
      opacity: 1,
    });


    const maxSize = Math.floor(Math.random() * Math.floor(5));
    const rockOffset = 40;
    

    for (let i = 0; i < Math.floor(Math.random() * Math.floor(10)); i++) {
      this.rockGeometry = new THREE.SphereBufferGeometry(
        Math.random() * Math.floor(maxSize),
        8,
        8
      );
  
      //A Mesh need a geometry and material object
      const meshRock = new THREE.Mesh(this.rockGeometry, this.rockMaterial);
      meshRock.name = "Rock" + i;

      meshRock.position.y =  Math.floor(Math.random() * Math.floor(rockOffset)) - Math.floor(Math.random() * Math.floor(rockOffset)) ;
      meshRock.position.x =  Math.floor(Math.random() * Math.floor(rockOffset)) - Math.floor(Math.random() * Math.floor(rockOffset)) ;
      meshRock.position.z = 90 - Math.floor(Math.random() * Math.floor(10))

      
      // We add each rock to the same group
      this.rockGroup.add(meshRock)
      this.rockGroup.name = 'rocks'  
    }

    // We add rock group to the planet, rock group moving and rotate with planet
    this.planet.add(this.rockGroup);
    console.timeEnd('rocks')
  };

  generateGalaxie = ({x,y,z}) => {
    console.time('galaxie')
    console.log('generate galaxie')
    // Destroy old galaxie
    if( this.galaxie !== null){
        this.geometry.dispose()
        this.material.dispose()
        this.scene.remove(this.galaxie)
    }

    // Geometry
    this.geometry = new THREE.BufferGeometry()
    const positions = new Float32Array(this.parameters.count * 3)
    const colors = new Float32Array(this.parameters.count * 3)

    const insideColor =  new THREE.Color(this.parameters.insideColor)
    const outsideColor = new THREE.Color(this.parameters.outsideColor)

    for(let i = 0 ; i < this.parameters.count; i ++) {
        const i3 = i * 3
        const radius = Math.random()  * this.parameters.radius
        const spinAngle = radius * this.parameters.spin
        const armAngle = (i % this.parameters.arms) / this.parameters.arms * Math.PI * 2

        // Position
        const randomY = Math.pow(Math.random(), this.parameters.randomnessPower)  * (Math.random() < 0.5 ? 1 : -1)
        const randomZ = Math.pow(Math.random(), this.parameters.randomnessPower)  * (Math.random() < 0.5 ? 1 : -1)
        const randomX = Math.pow(Math.random(), this.parameters.randomnessPower)  * (Math.random() < 0.5 ? 1 : -1)


        positions[i3 + 0] = Math.cos(armAngle + spinAngle) * radius + randomX
        positions[i3 + 1] = randomY
        positions[i3 + 2] = Math.sin(armAngle + spinAngle) * radius + randomZ


        // Colors  
        const mixedColor = insideColor.clone();
        mixedColor.lerp(outsideColor, radius / this.parameters.radius)


        colors[i3 + 0] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b
    }
    this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    this.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    // Material

    this.material =  new THREE.PointsMaterial(
        {
            size: this.parameters.size,
            sizeAttenuation : true,
            depthWrite : false,
            blending : THREE.AdditiveBlending,
            vertexColors : true
        }
    )

    this.galaxie = new THREE.Points(this.geometry, this.material)
    this.galaxie.rotation.x = 0.5
    this.galaxie.position.set(x,y,z)
    this.galaxie.scale.set(800,800,800)
    this.scene.add(this.galaxie)
    console.timeEnd('galaxie')

}




  /**
   * Generate Lights
   */
  generateLights = () =>{
    // AmbiantLight
    this.ambientLight= new THREE.AmbientLight(0xffffff, 1)
    this.scene.add(this.ambientLight)

    // PointLight
    this.pointLight = new THREE.PointLight(0xffffff, 100, 500)
    this.scene.add(this.pointLight)

    // DirectionalLight
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.01)
    this.directionalLight.position.set(0, 0, 600)
    this.directionalLightHelper = new THREE.DirectionalLightHelper(this.directionalLight, 1000, 0xff0000)
    this.directionalLightHelper.visible = false
    this.scene.add(this.directionalLight , this.directionalLightHelper)
  }

 
  /** Animation */
  animations = () => {
    this.controls.update();
    const elapsedTime = this.clock.getElapsedTime();

    // Card
    this.card.rotation.y = elapsedTime * Math.PI * 2 * -0.01;

    //Sun 
    this.sun.rotation.y = elapsedTime * Math.PI * 2 * 0.001;
    
    // Planet
    this.planet.rotation.y =  elapsedTime * Math.PI * 2 * 0.02;

    // Galaxie
    this.galaxie.rotation.y = elapsedTime * Math.PI * 2 * 0.02;

    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(this.animations);

  };



  render() {
    return (
      <div id="web-gl-scene">
        {!this.props.isLogin  && <NavLink to="/portfolio">
          <img src="svg/close.svg" alt="return to home" />
        </NavLink>
         }
      </div>
    );
  }
}

export default Scene;
