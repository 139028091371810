// React
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

//Custom component
import Scene from '../../components/demo/threejsScene/ThreejsScene';

// Custom Hook
import AuthConsumer  from '../../hook/useAuth';

// Project conf
import conf from '../../config/config-dev';

// Style
import "./login.css";


export default function Login() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const passwordInputRef = useRef(null);
    const [message , setMessage] = useState('');
    const [password , setPassword] = useState('');


    const { login } = AuthConsumer();

    const [audio, setAudio] = useState(null);

    useEffect(()=> {
        setPassword(window.location.href.split('=')[1] || null);
        const audio = new Audio("media/music/sound2.mp3");
        audio.volume = 0.1
        setAudio(audio);  
    },[])


    const handleSubmit = (e) => {
        setMessage()
        e.preventDefault();
        
        if(passwordInputRef.current.value === conf.auth.PASSWORD ) {
        
            audio.play();
          
            setMessage("");
            login().then(() => {
                console.log("Login successful. Navigating...");
                navigate(state?.path || "/portfolio");
            });
        }
        else{
            setMessage("Incorrect password");
        }
    }

    return (
        <div className="login" >
            <div className='authContainer'>
    
                <div className='authBox'>
                    <div className="logo">
                        <img src="./svg/logo.svg"  alt="logo" />
                    </div>
              
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="inputUser" className="col-sm-2 col-form-label">User</label>
                            <div className="col-sm-10">
                                <input type="text" readOnly className="form-control-plaintext" id="inputUser" value='Recruiter Only' />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Pass</label>
                            <div className="col-sm-10">
                                <input ref={passwordInputRef}  type="password" className="form-control-plaintext" id="inputPassword" defaultValue={password}/>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-secondary w-100 my-3 p-3">Confirmer</button>
                        {message  && <div className='w-100 text-danger text-center p-2'>{message}</div>}
        
                    </form>
                </div>
     
            </div>
            <Scene isLogin={true}/>
        
        </div>
    )
}