import React, { Component } from "react";
import "./youtubePlayer.css";

class YoutubePlayer extends Component {
  title = "";
  url = "";
  width = null;
  className ="";

  constructor({title, url, className, width}) {
    super()
    this.width = width;
    this.title = title;
    this.url = url;
    this.className = className;
  }

  render() {
    return (
      <div className={this.className}>
        <h5 className="text-success">{this.title}</h5>
        <div className="iframe-container">
          <iframe
            src={this.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            data-cookieconsent="marketing"
            title={this.title}
            with={this.width ? "100%" : this.width }
          >
            Pour profiter pleinement des vidéos Youtube directement sur cette
            page désactivez votre bloqueur de publicités pour ce site uniquement{" "}
          </iframe>
        </div>
      </div>
    );
  }
}
export default YoutubePlayer;
