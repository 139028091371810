import React, { Component } from "react";
import Banner from "../../partials/banner/Banner";
import "./contact.css";
import Mailto from "./mailto/Mailto";
import Map from "./map/Map";
import { ContextConsumer } from '../../../context/context';// imp

class Contact extends Component {
  constructor(props){
    super()
    this.state = {};
  }
  render() {


    return (
      <ContextConsumer>
      {
        ({i18n, lang}) => {
          const  i18nContact = i18n?.portfolio?.contact;
          return (
            <div className="contact" id="contact">
              <Banner
                title={i18nContact?.section_title[lang]}
                background="background-7.jpg"
                position={6}
                handleMouseEnter={this.props.handleMouseEnter}
              />
              <Mailto i18n={i18nContact?.mailtoCard} lang={lang} />
              <Map  i18n={i18nContact?.mapWidget} lang={lang} />
            </div>
          );
        }
      } 
      </ContextConsumer>
    )
  }
}

export default Contact;
