// React
import React from "react";
import { Route, Routes } from "react-router-dom";

// Style
import "./App.css";
import "aos/dist/aos.css";

// Custom components
import RequireAuthGuard from "./components/auth/requireAuthGuard";
import Portfolio from "./pages/portfolio/Portfolio";
import Downloads from "./pages/downloads/Downloads";
import Demo from "./pages/demo/Demo";
import Login from "./pages/login/login";

export default function App() {
  return (
    <div className="App" id="app">
      <Routes>
        <Route path="/" element={<Login />} />

        <Route
          path="/portfolio"
          element={
            <RequireAuthGuard>
              <Portfolio />
            </RequireAuthGuard>
          }
        />

        <Route
          path="/downloads"
          element={
            <RequireAuthGuard>
              <Downloads />
            </RequireAuthGuard>
          }
        />

        <Route
          path="/demo"
          element={
            <RequireAuthGuard>
              <Demo />
            </RequireAuthGuard>
          }
        />
      </Routes>
    </div>
  );
}
