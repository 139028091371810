import React from 'react';
import './mailto.css';

export default function Mailto ({i18n, lang}) {

    return (
      <div className="container my-5">
        <div className="row">
          <div className="col my-5">
            <div className="card text-center">
              <div className="card-header">
                <h5 className="card-title">{i18n?.title[lang]}</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <p className="card-text text-center">{i18n?.email[lang]}</p>
                    <p>
                      <img src="img/arobase.png" alt="" className="flat-icon" />{" "}
                      <em className="text-success">inf-dev@outlook.com</em>
                    </p>
                  </div>
                  <div className="col">
                    <p>{i18n?.socialMedia[lang]}</p>
                    <p>
                      <img
                        className="flat-icon"
                        src="img/linkedin.png"
                        alt=""
                      />{" "}
                      <a href="https://www.linkedin.com/in/cyril-vassallo/">
                        Cyril Vassallo
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-footer text-muted text-center">
                {i18n?.policy[lang]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

