import React from 'react';
import Carousel  from 'react-bootstrap/Carousel'
import './slider.css'

function Slider({images, lang}) {
  return (
    <Carousel>
      {images.map((image, key)=>{
        return (
          <Carousel.Item key={key}>
            <img className="d-block w-100" src={image.path} alt={image.title[lang]} />
            <Carousel.Caption>
              <h3 className="title">{image.title[lang]}</h3>
              <p className="description">{image.description[lang]}</p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })
    }
    </Carousel>
    ); 
}

export default Slider;