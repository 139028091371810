import React, { useState } from "react";
import {fadeInEffect, fadeOutEffect} from './../../../services/utils'
import { ContextConsumer } from "../../../context/context";
import "./spacer.css";

function Spacer({ spacerNumber = "0" }) {
  /**
   * hook on functional component
   */
  let [isClosed, setIsClosed] = useState(true);

  /**
   * Toggle project detail display
   * @param {object} event
   */
  const handleCLickToggle = (event) => {
    let target = document.querySelector("#details" + spacerNumber);
    if (target.style.display === "flex") {
      document.querySelector("#sound").play();
      fadeOutEffect(target);
      setIsClosed(true);
      target.previousSibling.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    } else {
      document.querySelector("#sound").play();
      fadeInEffect(target,60);
      setIsClosed(false);
      target.previousSibling.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  };



    return (
    <ContextConsumer>
      { ({i18n, lang}) => {
        const i18nSpacer = i18n?.common?.spacer;

        return (
          <div className="container spacer mb-5">
            <div className="row">
              <div className="col text-center mb-1">
                {isClosed ? (
                  <p className="text-success" onClick={handleCLickToggle}>
                  {i18nSpacer.labelOpen[lang]}
                  </p>
                ) : (
                  <p className="text-danger" onClick={handleCLickToggle}>
                  {i18nSpacer.labelClose[lang]}
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-5 text-right">_____________</div>
              <div className="col-2 text-center img-container" onClick={handleCLickToggle}>
                {isClosed ? (
                  <img src="img/arowdown.png" alt="" />
                ) : (
                  <img src="img/arowup.png" alt="" />
                )}
              </div>
              <div className="col-5">_____________</div>
            </div>
            <audio id="sound">
              <source src="media/music/sound.mp3" />
            </audio>
          </div>
        );
      }}
    </ContextConsumer>
    )
}

export default Spacer;
