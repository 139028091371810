import React, { Component } from 'react';
import './timeline.css';
import { ContextConsumer } from '../../../../context/context';

class Timeline extends Component {


  render() {

    return (
      <ContextConsumer>
       {
        ({i18n, lang}) => {
          const  i18nTimeline = i18n?.portfolio?.carrer?.timeline;
            return (
            <div className="timeline container my-5">
              {
                i18nTimeline?.map((experience, index) => {
                  return (
                  <div className="row right my-5" key={index}>
                    <div className="col">
                      <h3>{experience.job?.[lang]}</h3>
                      <p className="font-weight-bolder text-primary">
                      {experience.date?.[lang]} {experience.company?.[lang]}
                      </p>
                      <p>
                        {experience.description?.[lang]}
                      </p>
                      <ul>
                        {
                          experience.list.map((item, key) => <li key={key}> {item?.[lang]}</li>)
                        }
                      </ul>
                      <p className="col"></p>
                    </div>
                  </div>
                ) })
              }
            </div>
            )
          }
       } 
      </ContextConsumer>
    )
    
  }


}

export default Timeline;