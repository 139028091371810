import React, { Component } from "react";
import { ContextConsumer } from '../../../../context/context';


class Technologies extends Component {
  state = {};
  render() {
    return (
      <ContextConsumer> 
        {
          ({i18n, lang}) => {
            const i18nTechnologies = i18n?.portfolio?.skills?.technologies;
            return (
            <div className="container Techno  my-5">
              {i18nTechnologies?.map((techno, index) => {  
                  return index % 2 ? (
                      <div key={index} className="row my-5">
                        <div className="col-md-6">
                          <h3>{techno?.title?.[lang]}</h3>
                          <p>
                            {techno?.description?.[lang]}
                          </p>
                      </div>
                      </div>
                      )
                    : 
                    (
                    <div key={index} className="row my-5">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <h3>{techno?.title?.[lang]}</h3>
                        <p>
                          {techno?.description?.[lang]}
                        </p>
                      </div>
                    </div>) 
              })}      
            </div>     
          ) 
        }
      }
      </ContextConsumer>
    );
  }
}

export default Technologies;
