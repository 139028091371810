import React, { Component } from 'react';
import Weather from './weather/Weather';
import Banner from '../../partials/banner/Banner';
import Planet3DSight from './planet3DSight/Planet3DSight';
import AnimatePetPark from './animatePetPark/AnimatePetPark';
import { ContextConsumer } from '../../../context/context';// import Ehro from './ehro/Ehro';

class Demonstrations extends Component {
  constructor(props){
    super()
    this.state = {}
  }
    render() {

      return (
        <ContextConsumer>
        {
          ({i18n, lang}) => {
            const  i18nDemo = i18n?.portfolio?.demo;
            return (
              <div className="demonstrations" id="demonstrations">
                    
                <Banner
                  title={i18nDemo?.section_title?.[lang]}
                  background="background-4.jpg"
                  position={5}
                  handleMouseEnter={this.props.handleMouseEnter}
                />
                {/* <Ehro /> */}
                <AnimatePetPark i18n={i18nDemo?.petWidget} lang={lang} />
                <Planet3DSight i18n={i18nDemo?.planetWidget} lang={lang} />
                <Weather  i18n={i18nDemo?.weatherWidget} lang={lang} />
              </div>
            );
          }
        } 
        </ContextConsumer>
      )

    }
}

export default Demonstrations;