import React from 'react';
import './animatePetPark.css';
import Pet from './pet/Pet';

export default function AnimatePetPark ({i18n, lang = 'en'}) {

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <h3 className="text-primary">{i18n?.title[lang]}</h3>
          <div className="page--integration">
            <h2>{i18n?.subTitle[lang]}</h2>
            <p>
              {i18n?.description[lang]}
            </p>
            <div className="pet-park">
              <Pet/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}